@charset "UTF-8";
/* Colors */
/* Fonts */
@font-face {
  font-family: "vai-icons";
  src: url("../fonts/vai-icons.eot?95963887");
  src: url("../fonts/vai-icons.eot?95963887#iefix") format("embedded-opentype"), url("../fonts/vai-icons.woff2?95963887") format("woff2"), url("../fonts/vai-icons.woff?95963887") format("woff"), url("../fonts/vai-icons.ttf?95963887") format("truetype"), url("../fonts/vai-icons.svg?95963887#vai-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=vai-icon-]:before, [class*=" vai-icon-"]:before {
  font-family: "vai-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.vai { /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}
.vai-icon-down:before {
  content: "\e800";
}
.vai-icon-phone:before {
  content: "\e801";
}
.vai-icon-mail:before {
  content: "\e802";
}
.vai-icon-globe:before {
  content: "\e803";
}
.vai-icon-location:before {
  content: "\e804";
}
.vai-icon-info:before {
  content: "\e805";
}
.vai-icon-up:before {
  content: "\e806";
}
.vai-icon-fax:before {
  content: "\f1ac";
}
.vai-icon-copyright:before {
  content: "\f1f9";
}

/* MOVE THESE */
pre.debug-block {
  background: #eee;
  margin: 1em;
  border: 1px solid #333;
  border-radius: 5px;
  padding: 0.5em;
}
pre.debug-block.warning {
  background: #ffcc80;
}
pre.debug-block.danger {
  background: #f9adad;
}
pre.debug-block.success {
  background: #baf9ad;
}
pre.debug-block.info {
  background: #adc0f9;
}

.way-offscreen {
  position: absolute;
  top: -999999px;
  left: -9999999px;
}

/*
* Sass for http://propublica.github.io/stateface/ icon set.
* also adopted some code from other contributors:
*  - replacement: Paul Smith https://github.com/paulsmith
*  - FIPS state abbreviations: https://github.com/joetric
*/
@font-face {
  font-family: "sf-regular";
  src: url("../fonts/stateface/stateface-regular-webfont.eot");
  src: url("../fonts/stateface/stateface-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/stateface/stateface-regular-webfont.woff") format("woff"), url("../fonts/stateface/stateface-regular-webfont.ttf") format("truetype"), url("../fonts/stateface/stateface-regular-webfont.svg#sf-regular") format("svg");
  font-style: normal;
  font-weight: normal;
}
.sf-replace.sf-guam,
.sf-replace.sf-gu,
.sf-replace.sf-66, .sf-replace.sf-puerto-rico,
.sf-replace.sf-pr,
.sf-replace.sf-72, .sf-replace.sf-district-of-columbia,
.sf-replace.sf-dc,
.sf-replace.sf-11001, .sf-replace.sf-wyoming,
.sf-replace.sf-wy,
.sf-replace.sf-56, .sf-replace.sf-wisconsin,
.sf-replace.sf-wi,
.sf-replace.sf-55, .sf-replace.sf-west-virginia,
.sf-replace.sf-wv,
.sf-replace.sf-54, .sf-replace.sf-washington,
.sf-replace.sf-wa,
.sf-replace.sf-53, .sf-replace.sf-virginia,
.sf-replace.sf-va,
.sf-replace.sf-51, .sf-replace.sf-vermont,
.sf-replace.sf-vt,
.sf-replace.sf-50, .sf-replace.sf-utah,
.sf-replace.sf-ut,
.sf-replace.sf-49, .sf-replace.sf-texas,
.sf-replace.sf-tx,
.sf-replace.sf-48, .sf-replace.sf-tennessee,
.sf-replace.sf-tn,
.sf-replace.sf-47, .sf-replace.sf-south-dakota,
.sf-replace.sf-sd,
.sf-replace.sf-46, .sf-replace.sf-south-carolina,
.sf-replace.sf-sc,
.sf-replace.sf-45, .sf-replace.sf-rhode-island,
.sf-replace.sf-ri,
.sf-replace.sf-44, .sf-replace.sf-pennsylvania,
.sf-replace.sf-pa,
.sf-replace.sf-42, .sf-replace.sf-oregon,
.sf-replace.sf-or,
.sf-replace.sf-41, .sf-replace.sf-oklahoma,
.sf-replace.sf-ok,
.sf-replace.sf-40, .sf-replace.sf-ohio,
.sf-replace.sf-oh,
.sf-replace.sf-39, .sf-replace.sf-north-dakota,
.sf-replace.sf-nd,
.sf-replace.sf-38, .sf-replace.sf-north-carolina,
.sf-replace.sf-nc,
.sf-replace.sf-37, .sf-replace.sf-new-york,
.sf-replace.sf-ny,
.sf-replace.sf-36, .sf-replace.sf-new-mexico,
.sf-replace.sf-nm,
.sf-replace.sf-35, .sf-replace.sf-new-jersey,
.sf-replace.sf-nj,
.sf-replace.sf-34, .sf-replace.sf-new-hampshire,
.sf-replace.sf-nh,
.sf-replace.sf-33, .sf-replace.sf-nevada,
.sf-replace.sf-nv,
.sf-replace.sf-32, .sf-replace.sf-nebraska,
.sf-replace.sf-ne,
.sf-replace.sf-31, .sf-replace.sf-montana,
.sf-replace.sf-mt,
.sf-replace.sf-30, .sf-replace.sf-missouri,
.sf-replace.sf-mo,
.sf-replace.sf-29, .sf-replace.sf-mississippi,
.sf-replace.sf-ms,
.sf-replace.sf-28, .sf-replace.sf-minnesota,
.sf-replace.sf-mn,
.sf-replace.sf-27, .sf-replace.sf-michigan,
.sf-replace.sf-mi,
.sf-replace.sf-26, .sf-replace.sf-massachusetts,
.sf-replace.sf-ma,
.sf-replace.sf-25, .sf-replace.sf-maryland,
.sf-replace.sf-md,
.sf-replace.sf-24, .sf-replace.sf-maine,
.sf-replace.sf-me,
.sf-replace.sf-23, .sf-replace.sf-louisiana,
.sf-replace.sf-la,
.sf-replace.sf-22, .sf-replace.sf-kentucky,
.sf-replace.sf-ky,
.sf-replace.sf-21, .sf-replace.sf-kansas,
.sf-replace.sf-ks,
.sf-replace.sf-20, .sf-replace.sf-iowa,
.sf-replace.sf-ia,
.sf-replace.sf-19, .sf-replace.sf-indiana,
.sf-replace.sf-in,
.sf-replace.sf-18, .sf-replace.sf-illinois,
.sf-replace.sf-il,
.sf-replace.sf-17, .sf-replace.sf-idaho,
.sf-replace.sf-id,
.sf-replace.sf-16, .sf-replace.sf-hawaii,
.sf-replace.sf-hi,
.sf-replace.sf-15, .sf-replace.sf-georgia,
.sf-replace.sf-ga,
.sf-replace.sf-13, .sf-replace.sf-florida,
.sf-replace.sf-fl,
.sf-replace.sf-12, .sf-replace.sf-delaware,
.sf-replace.sf-de,
.sf-replace.sf-10, .sf-replace.sf-connecticut,
.sf-replace.sf-ct,
.sf-replace.sf-09, .sf-replace.sf-colorado,
.sf-replace.sf-co,
.sf-replace.sf-08, .sf-replace.sf-california,
.sf-replace.sf-ca,
.sf-replace.sf-06, .sf-replace.sf-arkansas,
.sf-replace.sf-ar,
.sf-replace.sf-05, .sf-replace.sf-arizona,
.sf-replace.sf-az,
.sf-replace.sf-04, .sf-replace.sf-alaska,
.sf-replace.sf-ak,
.sf-replace.sf-02, .sf-replace.sf-alabama,
.sf-replace.sf-al,
.sf-replace.sf-01 {
  position: relative;
  display: inline-block;
  min-width: 1em;
  text-indent: -999em;
}
.sf-replace.sf-guam:before,
.sf-replace.sf-gu:before,
.sf-replace.sf-66:before, .sf-replace.sf-puerto-rico:before,
.sf-replace.sf-pr:before,
.sf-replace.sf-72:before, .sf-replace.sf-district-of-columbia:before,
.sf-replace.sf-dc:before,
.sf-replace.sf-11001:before, .sf-replace.sf-wyoming:before,
.sf-replace.sf-wy:before,
.sf-replace.sf-56:before, .sf-replace.sf-wisconsin:before,
.sf-replace.sf-wi:before,
.sf-replace.sf-55:before, .sf-replace.sf-west-virginia:before,
.sf-replace.sf-wv:before,
.sf-replace.sf-54:before, .sf-replace.sf-washington:before,
.sf-replace.sf-wa:before,
.sf-replace.sf-53:before, .sf-replace.sf-virginia:before,
.sf-replace.sf-va:before,
.sf-replace.sf-51:before, .sf-replace.sf-vermont:before,
.sf-replace.sf-vt:before,
.sf-replace.sf-50:before, .sf-replace.sf-utah:before,
.sf-replace.sf-ut:before,
.sf-replace.sf-49:before, .sf-replace.sf-texas:before,
.sf-replace.sf-tx:before,
.sf-replace.sf-48:before, .sf-replace.sf-tennessee:before,
.sf-replace.sf-tn:before,
.sf-replace.sf-47:before, .sf-replace.sf-south-dakota:before,
.sf-replace.sf-sd:before,
.sf-replace.sf-46:before, .sf-replace.sf-south-carolina:before,
.sf-replace.sf-sc:before,
.sf-replace.sf-45:before, .sf-replace.sf-rhode-island:before,
.sf-replace.sf-ri:before,
.sf-replace.sf-44:before, .sf-replace.sf-pennsylvania:before,
.sf-replace.sf-pa:before,
.sf-replace.sf-42:before, .sf-replace.sf-oregon:before,
.sf-replace.sf-or:before,
.sf-replace.sf-41:before, .sf-replace.sf-oklahoma:before,
.sf-replace.sf-ok:before,
.sf-replace.sf-40:before, .sf-replace.sf-ohio:before,
.sf-replace.sf-oh:before,
.sf-replace.sf-39:before, .sf-replace.sf-north-dakota:before,
.sf-replace.sf-nd:before,
.sf-replace.sf-38:before, .sf-replace.sf-north-carolina:before,
.sf-replace.sf-nc:before,
.sf-replace.sf-37:before, .sf-replace.sf-new-york:before,
.sf-replace.sf-ny:before,
.sf-replace.sf-36:before, .sf-replace.sf-new-mexico:before,
.sf-replace.sf-nm:before,
.sf-replace.sf-35:before, .sf-replace.sf-new-jersey:before,
.sf-replace.sf-nj:before,
.sf-replace.sf-34:before, .sf-replace.sf-new-hampshire:before,
.sf-replace.sf-nh:before,
.sf-replace.sf-33:before, .sf-replace.sf-nevada:before,
.sf-replace.sf-nv:before,
.sf-replace.sf-32:before, .sf-replace.sf-nebraska:before,
.sf-replace.sf-ne:before,
.sf-replace.sf-31:before, .sf-replace.sf-montana:before,
.sf-replace.sf-mt:before,
.sf-replace.sf-30:before, .sf-replace.sf-missouri:before,
.sf-replace.sf-mo:before,
.sf-replace.sf-29:before, .sf-replace.sf-mississippi:before,
.sf-replace.sf-ms:before,
.sf-replace.sf-28:before, .sf-replace.sf-minnesota:before,
.sf-replace.sf-mn:before,
.sf-replace.sf-27:before, .sf-replace.sf-michigan:before,
.sf-replace.sf-mi:before,
.sf-replace.sf-26:before, .sf-replace.sf-massachusetts:before,
.sf-replace.sf-ma:before,
.sf-replace.sf-25:before, .sf-replace.sf-maryland:before,
.sf-replace.sf-md:before,
.sf-replace.sf-24:before, .sf-replace.sf-maine:before,
.sf-replace.sf-me:before,
.sf-replace.sf-23:before, .sf-replace.sf-louisiana:before,
.sf-replace.sf-la:before,
.sf-replace.sf-22:before, .sf-replace.sf-kentucky:before,
.sf-replace.sf-ky:before,
.sf-replace.sf-21:before, .sf-replace.sf-kansas:before,
.sf-replace.sf-ks:before,
.sf-replace.sf-20:before, .sf-replace.sf-iowa:before,
.sf-replace.sf-ia:before,
.sf-replace.sf-19:before, .sf-replace.sf-indiana:before,
.sf-replace.sf-in:before,
.sf-replace.sf-18:before, .sf-replace.sf-illinois:before,
.sf-replace.sf-il:before,
.sf-replace.sf-17:before, .sf-replace.sf-idaho:before,
.sf-replace.sf-id:before,
.sf-replace.sf-16:before, .sf-replace.sf-hawaii:before,
.sf-replace.sf-hi:before,
.sf-replace.sf-15:before, .sf-replace.sf-georgia:before,
.sf-replace.sf-ga:before,
.sf-replace.sf-13:before, .sf-replace.sf-florida:before,
.sf-replace.sf-fl:before,
.sf-replace.sf-12:before, .sf-replace.sf-delaware:before,
.sf-replace.sf-de:before,
.sf-replace.sf-10:before, .sf-replace.sf-connecticut:before,
.sf-replace.sf-ct:before,
.sf-replace.sf-09:before, .sf-replace.sf-colorado:before,
.sf-replace.sf-co:before,
.sf-replace.sf-08:before, .sf-replace.sf-california:before,
.sf-replace.sf-ca:before,
.sf-replace.sf-06:before, .sf-replace.sf-arkansas:before,
.sf-replace.sf-ar:before,
.sf-replace.sf-05:before, .sf-replace.sf-arizona:before,
.sf-replace.sf-az:before,
.sf-replace.sf-04:before, .sf-replace.sf-alaska:before,
.sf-replace.sf-ak:before,
.sf-replace.sf-02:before, .sf-replace.sf-alabama:before,
.sf-replace.sf-al:before,
.sf-replace.sf-01:before {
  position: absolute;
  top: 0;
  left: 0;
  text-indent: 0;
}
.sf-guam:before,
.sf-gu:before,
.sf-66:before, .sf-puerto-rico:before,
.sf-pr:before,
.sf-72:before, .sf-district-of-columbia:before,
.sf-dc:before,
.sf-11001:before, .sf-wyoming:before,
.sf-wy:before,
.sf-56:before, .sf-wisconsin:before,
.sf-wi:before,
.sf-55:before, .sf-west-virginia:before,
.sf-wv:before,
.sf-54:before, .sf-washington:before,
.sf-wa:before,
.sf-53:before, .sf-virginia:before,
.sf-va:before,
.sf-51:before, .sf-vermont:before,
.sf-vt:before,
.sf-50:before, .sf-utah:before,
.sf-ut:before,
.sf-49:before, .sf-texas:before,
.sf-tx:before,
.sf-48:before, .sf-tennessee:before,
.sf-tn:before,
.sf-47:before, .sf-south-dakota:before,
.sf-sd:before,
.sf-46:before, .sf-south-carolina:before,
.sf-sc:before,
.sf-45:before, .sf-rhode-island:before,
.sf-ri:before,
.sf-44:before, .sf-pennsylvania:before,
.sf-pa:before,
.sf-42:before, .sf-oregon:before,
.sf-or:before,
.sf-41:before, .sf-oklahoma:before,
.sf-ok:before,
.sf-40:before, .sf-ohio:before,
.sf-oh:before,
.sf-39:before, .sf-north-dakota:before,
.sf-nd:before,
.sf-38:before, .sf-north-carolina:before,
.sf-nc:before,
.sf-37:before, .sf-new-york:before,
.sf-ny:before,
.sf-36:before, .sf-new-mexico:before,
.sf-nm:before,
.sf-35:before, .sf-new-jersey:before,
.sf-nj:before,
.sf-34:before, .sf-new-hampshire:before,
.sf-nh:before,
.sf-33:before, .sf-nevada:before,
.sf-nv:before,
.sf-32:before, .sf-nebraska:before,
.sf-ne:before,
.sf-31:before, .sf-montana:before,
.sf-mt:before,
.sf-30:before, .sf-missouri:before,
.sf-mo:before,
.sf-29:before, .sf-mississippi:before,
.sf-ms:before,
.sf-28:before, .sf-minnesota:before,
.sf-mn:before,
.sf-27:before, .sf-michigan:before,
.sf-mi:before,
.sf-26:before, .sf-massachusetts:before,
.sf-ma:before,
.sf-25:before, .sf-maryland:before,
.sf-md:before,
.sf-24:before, .sf-maine:before,
.sf-me:before,
.sf-23:before, .sf-louisiana:before,
.sf-la:before,
.sf-22:before, .sf-kentucky:before,
.sf-ky:before,
.sf-21:before, .sf-kansas:before,
.sf-ks:before,
.sf-20:before, .sf-iowa:before,
.sf-ia:before,
.sf-19:before, .sf-indiana:before,
.sf-in:before,
.sf-18:before, .sf-illinois:before,
.sf-il:before,
.sf-17:before, .sf-idaho:before,
.sf-id:before,
.sf-16:before, .sf-hawaii:before,
.sf-hi:before,
.sf-15:before, .sf-georgia:before,
.sf-ga:before,
.sf-13:before, .sf-florida:before,
.sf-fl:before,
.sf-12:before, .sf-delaware:before,
.sf-de:before,
.sf-10:before, .sf-connecticut:before,
.sf-ct:before,
.sf-09:before, .sf-colorado:before,
.sf-co:before,
.sf-08:before, .sf-california:before,
.sf-ca:before,
.sf-06:before, .sf-arkansas:before,
.sf-ar:before,
.sf-05:before, .sf-arizona:before,
.sf-az:before,
.sf-04:before, .sf-alaska:before,
.sf-ak:before,
.sf-02:before, .sf-alabama:before,
.sf-al:before,
.sf-01:before {
  display: inline-block;
  margin-right: 5px;
  font-family: "sf-regular";
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  speak: none;
}

/* Icons */
.sf-alabama:before,
.sf-al:before,
.sf-01:before {
  content: "B";
}

.sf-alaska:before,
.sf-ak:before,
.sf-02:before {
  content: "A";
}

.sf-arizona:before,
.sf-az:before,
.sf-04:before {
  content: "D";
}

.sf-arkansas:before,
.sf-ar:before,
.sf-05:before {
  content: "C";
}

.sf-california:before,
.sf-ca:before,
.sf-06:before {
  content: "E";
}

.sf-colorado:before,
.sf-co:before,
.sf-08:before {
  content: "F";
}

.sf-connecticut:before,
.sf-ct:before,
.sf-09:before {
  content: "G";
}

.sf-delaware:before,
.sf-de:before,
.sf-10:before {
  content: "H";
}

.sf-florida:before,
.sf-fl:before,
.sf-12:before {
  content: "I";
}

.sf-georgia:before,
.sf-ga:before,
.sf-13:before {
  content: "J";
}

.sf-hawaii:before,
.sf-hi:before,
.sf-15:before {
  content: "K";
}

.sf-idaho:before,
.sf-id:before,
.sf-16:before {
  content: "M";
}

.sf-illinois:before,
.sf-il:before,
.sf-17:before {
  content: "N";
}

.sf-indiana:before,
.sf-in:before,
.sf-18:before {
  content: "O";
}

.sf-iowa:before,
.sf-ia:before,
.sf-19:before {
  content: "L";
}

.sf-kansas:before,
.sf-ks:before,
.sf-20:before {
  content: "P";
}

.sf-kentucky:before,
.sf-ky:before,
.sf-21:before {
  content: "Q";
}

.sf-louisiana:before,
.sf-la:before,
.sf-22:before {
  content: "R";
}

.sf-maine:before,
.sf-me:before,
.sf-23:before {
  content: "U";
}

.sf-maryland:before,
.sf-md:before,
.sf-24:before {
  content: "T";
}

.sf-massachusetts:before,
.sf-ma:before,
.sf-25:before {
  content: "S";
}

.sf-michigan:before,
.sf-mi:before,
.sf-26:before {
  content: "V";
}

.sf-minnesota:before,
.sf-mn:before,
.sf-27:before {
  content: "W";
}

.sf-mississippi:before,
.sf-ms:before,
.sf-28:before {
  content: "Y";
}

.sf-missouri:before,
.sf-mo:before,
.sf-29:before {
  content: "X";
}

.sf-montana:before,
.sf-mt:before,
.sf-30:before {
  content: "Z";
}

.sf-nebraska:before,
.sf-ne:before,
.sf-31:before {
  content: "c";
}

.sf-nevada:before,
.sf-nv:before,
.sf-32:before {
  content: "g";
}

.sf-new-hampshire:before,
.sf-nh:before,
.sf-33:before {
  content: "d";
}

.sf-new-jersey:before,
.sf-nj:before,
.sf-34:before {
  content: "e";
}

.sf-new-mexico:before,
.sf-nm:before,
.sf-35:before {
  content: "f";
}

.sf-new-york:before,
.sf-ny:before,
.sf-36:before {
  content: "h";
}

.sf-north-carolina:before,
.sf-nc:before,
.sf-37:before {
  content: "a";
}

.sf-north-dakota:before,
.sf-nd:before,
.sf-38:before {
  content: "b";
}

.sf-ohio:before,
.sf-oh:before,
.sf-39:before {
  content: "i";
}

.sf-oklahoma:before,
.sf-ok:before,
.sf-40:before {
  content: "j";
}

.sf-oregon:before,
.sf-or:before,
.sf-41:before {
  content: "k";
}

.sf-pennsylvania:before,
.sf-pa:before,
.sf-42:before {
  content: "l";
}

.sf-rhode-island:before,
.sf-ri:before,
.sf-44:before {
  content: "m";
}

.sf-south-carolina:before,
.sf-sc:before,
.sf-45:before {
  content: "n";
}

.sf-south-dakota:before,
.sf-sd:before,
.sf-46:before {
  content: "o";
}

.sf-tennessee:before,
.sf-tn:before,
.sf-47:before {
  content: "p";
}

.sf-texas:before,
.sf-tx:before,
.sf-48:before {
  content: "q";
}

.sf-utah:before,
.sf-ut:before,
.sf-49:before {
  content: "r";
}

.sf-vermont:before,
.sf-vt:before,
.sf-50:before {
  content: "t";
}

.sf-virginia:before,
.sf-va:before,
.sf-51:before {
  content: "s";
}

.sf-washington:before,
.sf-wa:before,
.sf-53:before {
  content: "u";
}

.sf-west-virginia:before,
.sf-wv:before,
.sf-54:before {
  content: "w";
}

.sf-wisconsin:before,
.sf-wi:before,
.sf-55:before {
  content: "v";
}

.sf-wyoming:before,
.sf-wy:before,
.sf-56:before {
  content: "x";
}

.sf-district-of-columbia:before,
.sf-dc:before,
.sf-11001:before {
  content: "y";
}

.sf-puerto-rico:before,
.sf-pr:before,
.sf-72:before {
  content: "3";
}

.sf-guam:before,
.sf-gu:before,
.sf-66:before {
  content: "4";
}

/* link underlines tend to make hypertext less readable,
   because underlines obscure the shapes of the lower halves of words */
:link, :visited {
  text-decoration: none;
}

/* no list-markers by default, since lists are used more often for semantics */
ul, ol {
  list-style: none;
}

/* avoid browser default inconsistent heading font-sizes */
/* and pre/code too */
h1, h2, h3, h4, h5, h6, pre, code {
  font-size: 1em;
}

/* remove the inconsistent (among browsers) default ul,ol padding or margin  */
/* the default spacing on headings does not match nor align with
   normal interline spacing at all, so let's get rid of it. */
/* zero out the spacing around pre, form, body, html, p, blockquote as well */
/* form elements are oddly inconsistent, and not quite CSS emulatable. */
/*  nonetheless strip their margin and padding as well */
ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, body, html, p, blockquote, fieldset, input {
  margin: 0;
  padding: 0;
}

/* whoever thought blue linked image borders were a good idea? */
a img, :link img, :visited img {
  border: none;
}

/* de-italicize address */
address {
  font-style: normal;
}

/* more varnish stripping as necessary... */
body {
  background: #f1f1f1;
}

/* HEADERS */
#header {
  border-bottom: 6px solid #396ba5;
}
#header .top-bar {
  background-color: #222;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}
#header .top-bar .top-bar-left .headerLogos {
  height: 45px;
}
#header .top-bar .top-bar-left .headerLogos h1 {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0.5rem;
  font-size: 1.55rem;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  color: #fefefe;
}
#header .top-bar .top-bar-left .headerLogos h1 i.sf-replace {
  color: #396ba5;
  font-size: 2.25em;
  margin-left: 5px;
  line-height: 1em;
}
#header .top-bar .top-bar-left .headerLogos h1 i.sf-replace::before {
  width: 100%;
  text-align: center;
}
#header .top-bar .top-bar-left .headerLogos h1 span.header-text {
  margin-left: -40px;
  z-index: 1;
  bottom: 2px;
  position: relative;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown {
  width: 65%;
  left: 0 !important;
  padding: 0;
  border: none;
  background: transparent;
  z-index: 100;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul {
  border-bottom: 2px solid #fefefe;
  padding-top: 1em;
  background: transparent;
  box-shadow: 0 1em 1em rgba(0, 0, 0, 0.5);
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul li {
  border-top: 2px solid #444;
  padding: 0.25em 0;
  background-color: #222;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 {
  display: flex;
  font-size: 5.5vw;
  align-items: center;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 > i.sf-replace {
  color: #396ba5;
  font-size: 2.5em;
  margin-left: 5px;
  line-height: 1em;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 > i.sf-replace::before {
  width: 100%;
  text-align: center;
}
#header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 > span {
  z-index: 1;
  position: relative;
  margin-left: -30px;
  bottom: 2px;
}
#header .top-bar .top-bar-left .headerTab {
  height: 24px;
  border-top: 2px solid #fefefe;
  background-color: #222;
}
#header .top-bar .top-bar-left .headerTab #statesList {
  background-color: #222;
}
#header .top-bar .top-bar-left .headerTab #statesList li {
  margin-top: 2px solid #222;
}
#header .top-bar .top-bar-left .headerTab #statesList li a {
  display: block;
  color: #fefefe;
  background-color: #222;
  padding: 0 0.5rem;
  font-size: 1.125em;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  line-height: 1.45em;
  margin-top: 2px;
}
#header .top-bar .top-bar-left .headerTab #statesList li a:hover {
  background-color: #CCC;
  color: #222;
}
#header .top-bar .top-bar-left .headerTab #statesList li.is-active a {
  background-color: #396ba5;
  color: #fff;
  cursor: default;
  border-left: 2px solid #222;
  border-right: 2px solid #222;
}
#header .top-bar .top-bar-left .headerTab #statesList li.is-active:first-child a {
  border-left: none;
}
#header .top-bar .top-bar-right .header-logo > img {
  padding-left: 1.5em;
  max-width: 300px;
}

/* Content */
#contWrapper {
  margin: auto;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#video_wrapper {
  position: relative;
}

#pageContent {
  margin: auto;
  background-color: #396ba5;
  position: relative;
}

#pageContent h2 {
  color: #ffffff;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

#pageContent h2 {
  font-size: 18px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

#pageContent h3 {
  font-size: 22px;
  color: #ffffff;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  background-color: #222;
  padding: 4px 12px;
}

#pageContent h4 {
  font-size: 15px;
  font-weight: bold;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

#pageContent h5 {
  font-size: 13px;
  font-weight: bold;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
}

#contentHeader {
  height: 40px;
}

.key span {
  font-size: 14px;
  padding-left: 24px;
  padding-top: 4px;
}
.key ul {
  margin-top: 2px;
  margin-bottom: 4px;
}
.key ul li {
  margin-left: 24px;
  padding-right: 12px;
  float: right;
  font-size: 12px;
  font-weight: none;
}

.key .gray {
  color: #fff;
}

.key .yellow {
  color: #E1D34B;
}

.key .blue {
  color: #5f8bff;
}

.yellow-link {
  color: #E1D34B;
}
.yellow-link:focus {
  color: #E1D34B;
}
.yellow-link:hover {
  background-color: #ccc;
  font-weight: 500;
  color: #0d2949 !important;
}

.blue-link {
  color: #5f8bff;
}
.blue-link:focus {
  color: #5f8bff;
}
.blue-link:hover {
  background-color: #ccc;
  font-weight: 500;
  color: #0d2949 !important;
}

.gray-link {
  color: #fefefe;
}
.gray-link:focus {
  color: #fefefe;
}
.gray-link:hover {
  background-color: #ccc;
  font-weight: 500;
  color: #0d2949 !important;
}

.darkgray-link {
  color: #aaa;
}
.darkgray-link:focus {
  color: #aaa;
}
.darkgray-link:hover {
  cursor: default;
  background-color: rgba(204, 204, 204, 0.1);
  color: #aaa !important;
}

#counties {
  height: 720px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-top: 18px;
}

#pageContent #counties dt {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  margin-top: -18px;
  margin-bottom: -4px;
}

#pageContent #counties dl {
  font-size: 16px;
}

#pageContent #counties dd {
  line-height: 22px;
}

#pageContent #counties dd a {
  display: block;
  padding-left: 24px;
  margin-right: 6px;
}

.back-shadow {
  background-color: #222;
  padding: 24px 0 24px 12px;
  width: 150px;
}

.back-shadow dd {
  margin-left: -14px;
}

#pageContent #cities .counties {
  display: none;
}

#pageContent #cities h4 {
  color: #ffffff;
}

#pageContent #cities li {
  font-size: 13px;
  font-weight: bold;
  margin: 0px;
  line-height: 15px;
}

#pageContent #cities a {
  color: #E1D34B;
}

#pageContent #cities a:hover {
  color: #ffffff;
  border-bottom: solid 1px #ffffff;
}

#pageContent #cities li ul li {
  margin-left: 2px;
}

#pageContent #cities .yellow-link a {
  color: #e1d34b;
} /* dea436 */
#pageContent #cities .yellow-link a:hover {
  color: #ffffff;
  border-bottom: solid 1px #e1d34b;
}

#pageContent #cities .blue-link a {
  color: #5f8bff;
}

#pageContent #cities .blue-link a:hover {
  color: #ffffff;
  border-bottom: solid 1px #5f8bff;
}

.responsive-embed, .flex-video {
  margin-bottom: 0;
}

#hoverContent {
  position: relative;
}
#hoverContent #hoverCurtain {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 5;
  border-radius: 5px;
}
#hoverContent #hoverCurtain.darkgray-link-container, #hoverContent #hoverCurtain.gray-link-container {
  background-color: #f2f2f2;
}
#hoverContent #hoverCurtain.yellow-link-container {
  background-color: #E8E1BE;
}
#hoverContent #hoverCurtain.blue-link-container {
  background-color: #DEEFFC;
}
#hoverContent.darkgray-link-container, #hoverContent.gray-link-container {
  background-color: #f2f2f2;
}
#hoverContent.yellow-link-container {
  background-color: #E8E1BE;
}
#hoverContent.blue-link-container {
  background-color: #DEEFFC;
}

#hoverBackDrop {
  background: rgba(34, 34, 34, 0.5);
  margin: 18px;
  border-radius: 5px;
  height: 740px;
}

#hoverContent {
  border-radius: 5px;
  height: 740px;
  width: calc(100% - 24px);
  position: relative;
}

#assbgImg {
  border-radius: 5px;
}

#assbgImg img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#key_class {
  position: absolute;
  top: 6px;
  right: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: rgba(34, 34, 34, 0.8);
}
#key_class span {
  padding: 0px 12px 0px 28px;
  font-size: 12px;
}

address {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  line-height: 18px;
  margin-top: 4px;
}
address span {
  font-size: 15px;
}

#offHours {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 10px;
}

#offHours ul {
  font-size: 16px;
  line-height: 18px;
}
#offHours ul span {
  font-size: 14px;
}

#offContact {
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  line-height: 18px;
  margin-top: 10px;
}
#offContact span {
  font-size: 14px;
}

#offContact li {
  padding-top: 2px;
}

#govInfo {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 10px;
}
#govInfo span {
  font-size: 14px;
}

#govInfo li {
  font-size: 16px;
  line-height: 18px;
}

#hasPrc img {
  width: 225px;
  height: 146px;
  box-shadow: -4px 4px 5px rgba(1, 1, 1, 0.3);
}
#hasPrc span {
  font-style: italic;
  font-size: 14px;
  padding-bottom: 4px;
  font-weight: bold;
}

#vanguardInfo {
  padding-left: 12px;
  padding-right: 12px;
  margin-top: 32px;
  position: absolute;
  bottom: 125px;
}

#vanguardInfo .adBtn {
  background-color: #356ba5;
  color: #ffffff;
  font-size: 12px;
  border-radius: 10px;
  cursor: pointer;
}

#vanguardInfo .adBtn:hover {
  background-color: #222;
  color: #ffffff;
  font-size: 12px;
  border-radius: 10px;
  cursor: pointer;
}

#vanguardInfo .adBtn img {
  float: right;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.btnClass {
  float: left;
  text-align: center;
  width: 72%;
  padding: 10px;
  line-height: 16px;
}

#assContact {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: #ccc;
  padding-bottom: 12px;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#contactAssInfo {
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 32px;
  width: 100%;
}
#contactAssInfo ul {
  padding-bottom: 4px;
  margin-top: -10px;
  word-wrap: break-word;
  white-space: normal;
  width: 75%;
}
#contactAssInfo ul li {
  width: 100%;
  margin-top: 0px;
  padding-top: none;
  white-space: normal;
  height: auto;
  word-wrap: break-word;
  padding-bottom: 2px;
}
#contactAssInfo ul li h4 {
  padding-top: 8px;
  line-height: 16px;
}
#contactAssInfo ul li a {
  white-space: normal;
  height: auto;
  word-wrap: break-word;
}

#assImg {
  width: 75px;
  height: 75px;
  float: left;
}

#assNoImg {
  width: 75px;
  height: 75px;
  float: left;
  display: none;
}

#assImg img {
  width: 100%;
  height: 75px;
  object-fit: cover;
}

#assInfo {
  padding-left: 6px;
  float: left;
  font-size: 14px;
  line-height: 16px;
}

#searchContent dl {
  padding: 12px;
}

#searchContent dt {
  font-size: 16px;
  line-height: 16px;
}

#searchContent dd {
  font-size: 14px;
  float: left;
  padding-right: 12px;
}

#hoverContent a {
  color: #396ba5;
}

#hoverContent a:hover {
  color: #396ba5;
  text-decoration: underline;
}

#hoverContent .header_links:hover {
  text-decoration: none;
}

#hoverContent .tel_hover {
  color: #000;
  text-decoration: none;
}

#hoverContent .tel_hover:hover {
  color: #000;
  text-decoration: none;
}

#hoverSection {
  height: 760px;
}

.LoginNote {
  font-size: 10px;
  font-style: italic;
  line-height: 10px;
}

#counterdiv {
  background-color: #222;
  color: #fff;
}

#pageCounter {
  float: right;
  color: #fff;
  text-align: right;
  padding: 2px 32px 4px 8px;
  font-size: 12px;
}

#warning {
  padding: 24px;
  font-weight: bold;
  background: #A8A8A8;
}

#warning p {
  margin-bottom: 12px;
}

#warning ul {
  text-align: right;
}

#warning li {
  display: inline;
  margin-right: 24px;
  padding: 6px;
  border: solid 2px #111111;
}

#additional {
  text-align: center;
}

#additional a:hover {
  border-bottom: solid 1px #5f8bff;
}

h6 {
  background-image: url(images/shaded-backdrop-6.png);
  background-size: 25%;
}

/* Ad - Banner */
.ad {
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

.banner.ad {
  width: 640px;
  height: 90px;
  border: solid 1px #dddddd;
  margin: auto;
}

#bannerAd {
  margin-bottom: 1em;
  padding-top: 35px;
}

/* Link Alert Pop-up */
#exitWarning {
  border-radius: 24px;
}
#exitWarning p {
  padding: 8px;
  line-height: 20px;
}
#exitWarning p .emailLink {
  color: #396ba5;
  font-weight: normal;
  text-decoration: underline;
}
#exitWarning p .textLink {
  color: #396ba5;
  font-weight: bold;
  text-decoration: underline;
}
#exitWarning p .altLink {
  color: #222;
  font-weight: bold;
  text-decoration: underline;
}
#exitWarning p .emailLink:hover {
  color: #575757;
  font-weight: noral;
  text-decoration: underline;
}
#exitWarning p .textLink:hover {
  color: #575757;
  font-weight: bold;
  text-decoration: underline;
}
#exitWarning p .altLink:hover {
  color: #575757;
  font-weight: bold;
  text-decoration: underline;
}
#exitWarning div {
  float: right;
  margin-bottom: -4px;
}
#exitWarning div .buttonCancel {
  color: #ffffff;
  background-color: #222;
  border-radius: 12px;
  padding: 8px 18px 8px 8px;
  margin: 12px 2px;
  border: 2px solid #222;
  cursor: pointer;
}
#exitWarning div .buttonCancel:hover {
  color: #222;
  background-color: #fff;
}

/* Footer */
#footer {
  background: #222;
  padding: 0;
  color: #adaaad;
  font-size: 12px;
  text-align: center;
}
#footer a {
  color: #adaaad;
}
#footer a:hover {
  color: #dea436;
  text-decoration: none;
}
#footer .banner.ad {
  display: none;
}
#footer #additional {
  background: #396ba5;
  padding: 1em;
}
#footer #additional a {
  color: #fefefe;
}
#footer #additional a:hover {
  color: #222;
  text-decoration: none;
}
#footer #additional ul.additional-links {
  margin: 6px;
}
#footer #additional ul.additional-links li {
  display: inline;
  padding: 6px;
  border-bottom: 1px solid #fefefe;
}
#footer #footerContent {
  padding: 12px 0;
}

@media screen and (min-width: 64em) {
  #mainContent {
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgb(34, 34, 34) 0%, rgba(34, 34, 34, 0.7) 25%, rgba(34, 34, 34, 0.7) 50%, rgba(34, 34, 34, 0.5) 75%, rgb(34, 34, 34) 100%);
  }
  #hasPrc {
    position: absolute;
    bottom: 175px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  #counterdiv {
    border-top: 2px solid #396ba5;
  }
  #video_wrapper {
    height: 778px;
  }
  #video_wrapper video {
    min-width: 71rem;
    min-height: 800px;
    max-height: 1000px;
    margin-top: -24px;
  }
  .responsive-embed.backscreen, .flex-video.backscreen {
    padding-bottom: 63%;
  }
  #VanguardAd {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
    background-size: 400% 400%;
    overflow: hidden;
    animation: gradient 20s ease infinite;
    margin-top: 32px;
    text-align: center;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  #VanguardAd p {
    position: relative;
    top: 25px;
    left: -7%;
    margin-top: 0px;
    font-size: 54px;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    z-index: 1;
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
    -webkit-animation: fadeOut 1 1s;
    -moz-animation: fadeOut 1 1s;
    -o-animation: fadeOut 1 1s;
    animation: fadeOut 1 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
  }
  #VanguardAd p span {
    font-size: 39px;
    color: #ffffff;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #VanguardAd .img_anima {
    position: relative;
    margin-top: 0px;
    animation-name: logoFade;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 5;
    left: 10%;
    top: -300px;
    transform: scale(0.2);
    -webkit-animation-delay: 2.25s;
    -moz-animation-delay: 2.25s;
    -o-animation-delay: 2.25s;
    animation-delay: 2.25s;
  }
  #VanguardAd .slogan {
    color: #fff;
    font-size: 14px;
    font-style: italic;
    margin-top: -10px;
    margin-left: 95px;
  }
  @keyframes logoFade {
    0% {
      left: 10%;
      top: -300px;
      transform: scale(0.2);
      opacity: 1;
    }
    100% {
      left: -50%;
      top: -250px;
      transform: scale(1);
      opacity: 0.1;
    }
  }
  #VanguardAd h2 {
    width: 100%;
    font-size: 42px;
    color: #ffffff;
    text-align: center;
    font-weight: 100;
    margin-top: -600px;
    z-index: 50;
  }
  #VanguardAd h3 {
    width: 100%;
    font-size: 20px;
    margin-top: -10px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    z-index: 50;
  }
  #VanguardAd h4 {
    width: 100%;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 12px;
    text-transform: uppercase;
    z-index: 50;
    color: #ffffff;
    text-align: center;
  }
  #VanguardAd .fade_header1, #VanguardAd .fade_header2, #VanguardAd .fade_header3 {
    opacity: 0;
  }
  #VanguardAd .fade_header1 {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    animation-delay: 4s;
  }
  #VanguardAd .fade_header2 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    -moz-animation-delay: 4.5s;
    -o-animation-delay: 4.55s;
    animation-delay: 4.5s;
  }
  #VanguardAd .fade_header3 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    animation-delay: 6s;
  }
  #VanguardAd .shimmer {
    animation: shimmer;
    -webkit-animation: shimmer;
    -moz-animation: shimmer;
    -o-animation: shimmer;
    -ms-animation: shimmer;
    color: rgba(241, 190, 72, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    -webkit-background-size: 75px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125x 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #fff;
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    -o-animation-delay: 8s;
    animation-delay: 8s;
    opacity: 1;
    z-index: 50;
    padding: 0px 125px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-o-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  #VanguardAd .buttonv {
    font-size: 24px;
    color: #ffffff;
    background-color: #222222;
    border-radius: 5px;
    border: 2px solid #222222;
    padding: 10px 12px 10px 12px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonw {
    font-size: 24px;
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 10px 24px 10px 24px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonv:hover {
    background-color: #ccc;
    border-radius: 5px;
    border: 2px solid #ccc;
    color: #222;
  }
  #VanguardAd .buttonw:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #222;
  }
  #VanguardAd div {
    padding-bottom: 18px;
    z-index: 50;
  }
  #footer {
    border-top: 4px solid #f1f1f1;
    margin-top: 36px;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.99875em) {
  #mainContent {
    position: absolute;
    top: 0;
    width: 100%;
    background: linear-gradient(to bottom, rgb(34, 34, 34) 0%, rgba(34, 34, 34, 0.7) 25%, rgba(34, 34, 34, 0.7) 50%, rgba(34, 34, 34, 0.5) 75%, rgb(34, 34, 34) 100%);
  }
  #video_wrapper {
    height: 778px;
  }
  #video_wrapper video {
    min-width: 82rem;
    min-height: 850px;
    max-height: 1000px;
  }
  #counterdiv {
    border-top: 2px solid #396ba5;
  }
  #hasPrc {
    position: absolute;
    bottom: 175px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
  }
  #contactAssInfo {
    padding-top: 6px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 32px;
    width: 100%;
  }
  #contactAssInfo ul {
    padding-bottom: 4px;
    margin-top: -10px;
    width: 65%;
    word-wrap: break-word;
    white-space: normal;
  }
  #contactAssInfo ul li {
    width: 100%;
    margin-top: 0px;
    padding-top: none;
    white-space: normal;
    height: auto;
    word-wrap: break-word;
  }
  #contactAssInfo ul li h4 {
    padding-top: 8px;
    white-space: normal;
    height: auto;
    line-height: 14px;
    word-wrap: break-word;
  }
  #contactAssInfo ul li a {
    white-space: normal;
    height: auto;
    word-wrap: break-word;
  }
  #VanguardAd {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
    background-size: 400% 400%;
    overflow: hidden;
    animation: gradient 20s ease infinite;
    margin-top: 32px;
    text-align: center;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  #VanguardAd p {
    position: relative;
    top: 25px;
    left: -10%;
    margin-top: 0px;
    font-size: 54px;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    z-index: 1;
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
    -webkit-animation: fadeOut 1 1s;
    -moz-animation: fadeOut 1 1s;
    -o-animation: fadeOut 1 1s;
    animation: fadeOut 1 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
  }
  #VanguardAd p span {
    font-size: 39px;
    color: #ffffff;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #VanguardAd img_anima {
    position: relative;
    margin-top: 0px;
    animation-name: logoFade;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 5;
    left: 11%;
    top: -300px;
    transform: scale(0.2);
    -webkit-animation-delay: 2.25s;
    -moz-animation-delay: 2.25s;
    -o-animation-delay: 2.25s;
    animation-delay: 2.25s;
  }
  #VanguardAd .slogan {
    color: #fff;
    font-size: 14px;
    font-style: italic;
    margin-top: -10px;
    margin-left: 95px;
  }
  @keyframes logoFade {
    0% {
      left: 10%;
      top: -300px;
      transform: scale(0.2);
      opacity: 1;
    }
    100% {
      left: -50%;
      top: -250px;
      transform: scale(1);
      opacity: 0.1;
    }
  }
  #VanguardAd h2 {
    width: 100%;
    font-size: 42px;
    color: #ffffff;
    text-align: center;
    font-weight: 100;
    margin-top: -600px;
    z-index: 50;
  }
  #VanguardAd h3 {
    width: 100%;
    font-size: 20px;
    margin-top: -10px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    z-index: 50;
  }
  #VanguardAd h4 {
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 12px;
    text-transform: uppercase;
    z-index: 50;
    color: #ffffff;
    text-align: center;
  }
  #VanguardAd .fade_header1, #VanguardAd .fade_header2, #VanguardAd .fade_header3 {
    opacity: 0;
  }
  #VanguardAd .fade_header1 {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    animation-delay: 4s;
  }
  #VanguardAd .fade_header2 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    -moz-animation-delay: 4.5s;
    -o-animation-delay: 4.55s;
    animation-delay: 4.5s;
  }
  #VanguardAd .fade_header3 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    animation-delay: 6s;
  }
  #VanguardAd .shimmer {
    animation: shimmer;
    -webkit-animation: shimmer;
    -moz-animation: shimmer;
    -o-animation: shimmer;
    -ms-animation: shimmer;
    color: rgba(241, 190, 72, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    -webkit-background-size: 75px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125x 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #fff;
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    -o-animation-delay: 8s;
    animation-delay: 8s;
    opacity: 1;
    z-index: 50;
    padding: 0px 75px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-o-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  #VanguardAd .buttonv {
    font-size: 24px;
    color: #ffffff;
    background-color: #222222;
    border-radius: 5px;
    border: 2px solid #222222;
    padding: 10px 12px 10px 12px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonw {
    font-size: 24px;
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 10px 24px 10px 24px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonv:hover {
    background-color: #ccc;
    border-radius: 5px;
    border: 2px solid #ccc;
    color: #222;
  }
  #VanguardAd .buttonw:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #222;
  }
  #VanguardAd div {
    padding-bottom: 18px;
    z-index: 50;
  }
  #footer {
    border-top: 4px solid #f1f1f1;
  }
}
@media screen and (max-width: 63.99875em) {
  body #header .top-bar .top-bar-left .headerLogos h1 {
    cursor: pointer;
    font-size: clamp(1.5em, 4vw, 1.75rem);
  }
  body #header .top-bar .top-bar-left .headerLogos .dropdown-pane {
    top: 62px !important;
  }
  body #header .top-bar .top-bar-left .headerLogos #statesDropdown ul li {
    padding: 0;
  }
  body #header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 {
    font-size: 1.75rem;
    bottom: 0;
  }
  body #header .top-bar .top-bar-right .header-logo > img {
    max-width: 250px;
  }
  #pageContent {
    height: 1456px;
    background-color: #222;
  }
  #mainContent {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1430px;
    background: linear-gradient(to bottom, rgb(34, 34, 34) 0%, rgba(34, 34, 34, 0.7) 25%, rgba(34, 34, 34, 0.7) 50%, rgba(34, 34, 34, 0.5) 75%, rgb(34, 34, 34) 100%);
  }
  #video_wrapper {
    height: 800px;
  }
  #video_wrapper video {
    min-width: 92rem;
    min-height: 1000px;
    max-height: 1000px;
  }
  #hoverSection {
    height: 625px;
    margin-top: -4px;
  }
  #hoverSection #counterdiv {
    border-top: 2px solid #396ba5;
  }
  #hoverBackDrop {
    background-color: #222;
    margin: 0px 4px 4px 4px;
    border-radius: 0px;
    height: 100%;
    border-top: 4px solid #222;
  }
  #hoverBackDrop #hoverContent {
    border-radius: 0px;
    height: 100%;
    width: 100%;
  }
  #offInfo {
    width: 40%;
    float: left;
  }
  #hasPrc {
    width: 60%;
    float: left;
  }
  #govInfo {
    width: 60%;
    float: left;
  }
  #assbgImg img {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  #VanguardAd {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
    background-size: 400% 400%;
    overflow: hidden;
    animation: gradient 20s ease infinite;
    margin-top: 32px;
    text-align: center;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  #VanguardAd p {
    position: relative;
    top: 25px;
    left: -8%;
    margin-top: 0px;
    font-size: 54px;
    color: #ffffff;
    text-align: center;
    line-height: 38px;
    z-index: 1;
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
    -webkit-animation: fadeOut 1 1s;
    -moz-animation: fadeOut 1 1s;
    -o-animation: fadeOut 1 1s;
    animation: fadeOut 1 1s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    -o-animation-delay: 2s;
    animation-delay: 2s;
  }
  #VanguardAd p span {
    font-size: 39px;
    color: #ffffff;
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #VanguardAd .img_anima {
    position: relative;
    margin-top: 0px;
    animation-name: logoFade;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    z-index: 5;
    left: 21%;
    top: -300px;
    transform: scale(0.2);
    -webkit-animation-delay: 2.25s;
    -moz-animation-delay: 2.25s;
    -o-animation-delay: 2.25s;
    animation-delay: 2.25s;
  }
  #VanguardAd .slogan {
    color: #fff;
    font-size: 14px;
    font-style: italic;
    margin-top: -10px;
    margin-left: 95px;
  }
  @keyframes logoFade {
    0% {
      left: 21%;
      top: -300px;
      transform: scale(0.2);
      opacity: 1;
    }
    100% {
      left: -50%;
      top: -250px;
      transform: scale(1);
      opacity: 0.1;
    }
  }
  #VanguardAd h2 {
    width: 100%;
    font-size: 42px;
    color: #ffffff;
    text-align: center;
    font-weight: 100;
    margin-top: -600px;
    z-index: 50;
  }
  #VanguardAd h3 {
    width: 100%;
    font-size: 20px;
    margin-top: -10px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    z-index: 50;
  }
  #VanguardAd h4 {
    width: 100%;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 12px;
    text-transform: uppercase;
    z-index: 50;
    color: #ffffff;
    text-align: center;
  }
  #VanguardAd .fade_header1, #VanguardAd .fade_header2, #VanguardAd .fade_header3 {
    opacity: 0;
  }
  #VanguardAd .fade_header1 {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    animation-delay: 4s;
  }
  #VanguardAd .fade_header2 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    -moz-animation-delay: 4.5s;
    -o-animation-delay: 4.55s;
    animation-delay: 4.5s;
  }
  #VanguardAd .fade_header3 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 6s;
    -moz-animation-delay: 6s;
    -o-animation-delay: 6s;
    animation-delay: 6s;
  }
  #VanguardAd .shimmer {
    animation: shimmer;
    -webkit-animation: shimmer;
    -moz-animation: shimmer;
    -o-animation: shimmer;
    -ms-animation: shimmer;
    color: rgba(241, 190, 72, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    -webkit-background-size: 75px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125x 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #fff;
    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
    -o-animation-delay: 8s;
    animation-delay: 8s;
    opacity: 1;
    z-index: 50;
    padding: 0px 75px;
    width: 100%;
    margin: 0px -70px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-o-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  #VanguardAd .buttonv {
    font-size: 18px;
    color: #ffffff;
    background-color: #222222;
    border-radius: 5px;
    border: 2px solid #222222;
    padding: 10px 12px 10px 12px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonw {
    font-size: 18px;
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 10px 24px 10px 24px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonv:hover {
    background-color: #ccc;
    border-radius: 5px;
    border: 2px solid #ccc;
    color: #222;
  }
  #VanguardAd .buttonw:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #222;
  }
  #VanguardAd div {
    padding-bottom: 18px;
    z-index: 50;
  }
  #footer {
    width: 100%;
    margin-top: 48px;
  }
}
@media screen and (max-width: 44.74875em) {
  body #header .top-bar {
    padding: 0;
  }
  body #header .top-bar .top-bar-left .headerLogos {
    padding-top: 10px;
  }
  body #header .top-bar .top-bar-left .headerLogos h1 {
    font-size: clamp(1.5em, 4vw, 3em);
    cursor: pointer;
  }
  body #header .top-bar .top-bar-left .headerLogos h1 > i.sf-replace {
    color: #396ba5;
    font-size: 2em;
    margin-left: 5px;
    line-height: 1em;
  }
  body #header .top-bar .top-bar-left .headerLogos h1 > i.sf-replace::before {
    width: 100%;
    text-align: center;
  }
  body #header .top-bar .top-bar-left .headerLogos h1 .header-text {
    margin-left: -1.5rem;
    font-size: clamp(1em, 4vw, 3em);
  }
  body #header .top-bar .top-bar-left .headerLogos .dropdown-pane {
    top: 31px !important;
  }
  body #header .top-bar .top-bar-left .headerLogos #statesDropdown {
    width: 100%;
  }
  body #header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 {
    font-size: clamp(1.4em, 4vw, 3em);
    background-color: #222;
  }
  body #header .top-bar .top-bar-left .headerLogos #statesDropdown ul li h1 span {
    margin-left: -2.5rem;
  }
  body #pageContent #mainContent {
    height: unset;
  }
  body #footer {
    width: 100%;
    margin-top: 24px;
  }
  body #footer #footerContent .cell {
    text-align: center;
  }
  body #footer #footerContent .cell.footer-meta {
    border-top: 1px solid #555;
    margin-top: 1em;
    padding-top: 1em;
  }
  .key ul {
    margin-left: 10px;
  }
  .key ul li {
    margin-left: 0px;
    padding-right: 16px;
    float: left;
    font-size: 10px;
  }
  #pageContent {
    height: 600px;
    background-color: #222;
    z-index: 50;
  }
  .illinois_mContent {
    height: 490px !important;
  }
  .iowa_mContent {
    height: 600px !important;
  }
  .minnesota_mContent {
    height: 490px !important;
  }
  .missouri_mContent {
    height: 492px !important;
  }
  .nebraska_mContent {
    height: 492px !important;
  }
  .northDakota_mContent {
    height: 455px !important;
  }
  .southDakota_mContent {
    height: 490px !important;
  }
  #mainContent {
    width: 100%;
    background-color: #222;
  }
  #contWrapper {
    padding-left: 0px;
    padding-right: 0px;
    max-width: unset;
    margin-top: -4px;
  }
  .button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {
    background-color: #222;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
  .button:hover, .button:focus {
    background-color: #222;
  }
  #mobileContHeader h2 {
    padding: 10px 2px 4px 10px;
  }
  #counties_m ul {
    margin: 10px 0px;
  }
  #counties_m ul li {
    box-shadow: 0px 2px 6px 4px rgba(50, 50, 50, 0.75);
  }
  #counties_m ul li ul {
    margin: 0px 0px;
  }
  #counties_m ul li ul li {
    border-bottom: 1px solid #222;
    border-top: 1px solid #222;
    box-shadow: none;
  }
  #counties_m h3 {
    font-size: 18px;
    color: #ffffff;
    color: #ffffff;
    font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
    background-color: none;
    padding: 0px 0px;
    line-height: 0.75;
  }
  .menu {
    background-color: #575757;
  }
  .accordion-menu .is-accordion-submenu a {
    padding: 0.7rem 2rem;
  }
  .accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
    left: 0.5rem;
  }
  #VanguardAd {
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;
    margin-top: 32px;
    text-align: center;
  }
  @keyframes gradient {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 50% 50%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
  #VanguardAd .bk_img {
    position: relative;
    background-image: url(https://www.iowaassessors.com/images/Vanguard_img/VANGUARD_White_Trans_Half_LOGO.png);
    background-repeat: no-repeat;
    background-position: left;
    height: 250px;
    align-items: center;
    justify-content: center;
    z-index: 25;
  }
  #VanguardAd h2 {
    width: 100%;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
    font-weight: 200;
    margin-top: 0px;
    z-index: 50;
    padding-top: 12px;
  }
  #VanguardAd h3 {
    width: 100%;
    font-size: 12px;
    margin-top: -5px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    z-index: 50;
  }
  #VanguardAd h4 {
    width: 100%;
    font-size: 10px;
    margin-top: 10px;
    margin-bottom: 12px;
    text-transform: uppercase;
    z-index: 50;
    color: #ffffff;
    text-align: center;
  }
  #VanguardAd .fade_header1, #VanguardAd .fade_header2, #VanguardAd .fade_header3 {
    opacity: 0;
  }
  #VanguardAd .fade_header1 {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  #VanguardAd .fade_header2 {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
  }
  #VanguardAd .fade_header3 {
    animation: fadeIn 4s;
    -webkit-animation: fadeIn 4s;
    -moz-animation: fadeIn 4s;
    -o-animation: fadeIn 4s;
    -ms-animation: fadeIn 4s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
    -moz-animation-delay: 3s;
    -o-animation-delay: 3s;
    animation-delay: 3s;
  }
  #VanguardAd .shimmer {
    animation: shimmer;
    -webkit-animation: shimmer;
    -moz-animation: shimmer;
    -o-animation: shimmer;
    -ms-animation: shimmer;
    color: rgba(241, 190, 72, 0.1);
    background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
    -webkit-background-size: 75px 100%;
    -moz-background-size: 125px 100%;
    background-size: 125x 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-animation-duration: 3s;
    -moz-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-color: #fff;
    -webkit-animation-delay: 4s;
    -moz-animation-delay: 4s;
    -o-animation-delay: 4s;
    animation-delay: 4s;
    opacity: 1;
    z-index: 50;
    padding: 0px 75px;
    width: 100%;
    margin: 0px -70px;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-webkit-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @-o-keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  @keyframes shimmer {
    0% {
      background-position: left;
    }
    100% {
      background-position: right;
    }
  }
  #VanguardAd .buttonv {
    font-size: 16px;
    color: #ffffff;
    background-color: #222222;
    border-radius: 5px;
    border: 2px solid #222222;
    padding: 10px 12px 10px 12px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
    opacity: 1;
  }
  #VanguardAd .buttonw {
    font-size: 16px;
    color: #ffffff;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 5px;
    padding: 10px 54px 10px 54px;
    margin: 12px;
    cursor: pointer;
    z-index: 50;
  }
  #VanguardAd .buttonv:hover {
    background-color: #ccc;
    border-radius: 5px;
    border: 2px solid #ccc;
    color: #222;
  }
  #VanguardAd .buttonw:hover {
    background-color: #fff;
    border: 2px solid #fff;
    color: #222;
  }
  #VanguardAd div {
    padding-bottom: 18px;
    z-index: 50;
  }
}