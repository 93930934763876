@import 'settings';
@import 'variables';
@import 'stateface';
@import 'htmlreset';

/* HEADERS */
#header {
	border-bottom: 6px solid $themeColor;
	.top-bar {
		background-color: $themeBlack;
		font-family: $gothic;
		.top-bar-left {
			.headerLogos {
				height:45px;		
				h1 {
					display: flex;
					align-items: center;
					position: relative;
					bottom: .5rem;
					font-size: 1.55rem;
					font-family: $gothic;
					color: $white;
					i.sf-replace {
						color: $themeColor;
						font-size: 2.25em;
						margin-left: 5px;
						line-height: 1em;
						&::before {
							width: 100%;
							text-align: center;
						}
					}
					span.header-text {
						margin-left: -40px;
						z-index: 1;
						bottom: 2px;
						position: relative;
					}
				}
				#statesDropdown {
					width: 65%;
					left: 0 !important;
					padding: 0;
					border: none;
					background: transparent;
					z-index:100;
					ul {
						border-bottom: 2px solid $white;
						padding-top: 1em;
						background: transparent;
						box-shadow: 0 1em 1em rgba(0,0,0,0.5);
						li {
							border-top: 2px solid #444;
							padding: .25em 0;
							background-color: $themeBlack;
							h1 {
								display: flex;
								font-size: 5.5vw;
								align-items: center;
								& > i.sf-replace {
									color: $themeColor;
									font-size: 2.5em;
									margin-left: 5px;
									line-height: 1em;
									&::before {
										width: 100%;
										text-align: center;
									}
								}
								& > span {
									z-index: 1;
									position: relative;
									margin-left: -30px;
									bottom: 2px;
								}
							}
						}
					}
				}
			}
			.headerTab {
				height:24px;
				border-top: 2px solid $white;
				background-color: $themeBlack;
				#statesList {
					background-color: $themeBlack;
					li {
						margin-top: 2px solid $themeBlack;
						a {
							display: block;
							color: $white;
							background-color: $themeBlack;
							padding: 0 0.5rem;							
							font-size: 1.125em;
							font-family: $gothic;
							line-height: 1.45em;
							margin-top:2px;
							&:hover {
								background-color: #CCC;
								color: $themeBlack;
							}
						}
						&.is-active {
							a {
								background-color: $themeColor;
								color: #fff;
								cursor: default;
								border-left:2px solid $themeBlack;
								border-right:2px solid $themeBlack;
							}
							&:first-child {
								a {
									border-left: none;
								}
							}
						}
					}
				}
			}
		}
		.top-bar-right {
			.header-logo {
				& > img {
					padding-left: 1.5em;
					max-width: 300px;
				}
			}
		}
	}
}

/* Content */
#contWrapper{margin: auto; }
#page-container {
  position: relative;
  min-height: 100vh;
}


#video_wrapper {position: relative; }


#pageContent{margin:auto;  background-color:$themeColor; position:relative;}
#pageContent h2{color:#ffffff; font-family: $gothic; }
#pageContent h2 {font-size:18px;font-family: $gothic; }
#pageContent h3{font-size:22px; color:#ffffff; font-family: $gothic; background-color:$themeBlack; padding:4px 12px; }
#pageContent h4{font-size:15px; font-weight:bold; font-family: $gothic; }
#pageContent h5{font-size:13px; font-weight:bold; font-family: $gothic; }

#contentHeader {height:40px;}
.key {
	span {
		font-size:14px;
		padding-left:24px;
		padding-top:4px;		
	}
	ul{
		margin-top:2px;
		margin-bottom:4px;
		li {
			margin-left:24px; padding-right:12px; float:right; font-size:12px; font-weight:none;
			
		}		
	}
}
.key .gray {color:#fff;}
.key .yellow {color:#E1D34B; }
.key .blue{color:#5f8bff;}

$links: (
    'yellow': #E1D34B,
    'blue': #5f8bff,
    'gray': $white
);
@each $color, $hex in $links {
  .#{$color}-link {
    color: $hex;
	&:focus {
		color: $hex;
	}
    &:hover {
      background-color:#ccc;
      font-weight: 500;
      color:#0d2949 !important;
    }  
    }  
}
.darkgray-link {
  color: #aaa;
  &:focus {
	color: #aaa;
  }
  &:hover {
    cursor:default; 
    background-color:rgba(204, 204, 204, .1);
    color:#aaa !important;
  }
}


#counties  {height: 720px; display:flex; flex-direction: column; flex-wrap:wrap;margin-left: 16px; margin-top:18px;}
#pageContent #counties dt{color:#ffffff; font-size:18px; font-weight: 300; font-family: $gothic; margin-top: -18px; margin-bottom:-4px;}
#pageContent #counties dl {font-size:16px; ; }
#pageContent #counties dd {line-height:22px; }
#pageContent #counties dd a {
	display: block;
	padding-left: 24px;
	margin-right: 6px;
}
.back-shadow {
	background-color: $themeBlack;
	padding: 24px 0 24px 12px;
	width: 150px;
}
.back-shadow dd {margin-left:-14px;}
.back-shadow dt { }
.back-shadow dd a {}

#pageContent #cities .counties{display:none;}
#pageContent #cities h4{color:#ffffff;}
#pageContent #cities li{font-size:13px;font-weight:bold;margin:0px;line-height:15px;}
#pageContent #cities a {color: #E1D34B;}
#pageContent #cities a:hover{color:#ffffff;border-bottom: solid 1px #ffffff;}

#pageContent #cities li ul li{margin-left:2px;}
#pageContent #cities .yellow-link a{color:#e1d34b;} /* dea436 */
#pageContent #cities .yellow-link a:hover{color:#ffffff;border-bottom:solid 1px #e1d34b;}
#pageContent #cities .blue-link a{color:#5f8bff;}
#pageContent #cities .blue-link a:hover{color:#ffffff;border-bottom:solid 1px #5f8bff;}

.responsive-embed, .flex-video {margin-bottom:0;}

#hoverContent {
	position: relative;
	#hoverCurtain {
		position: absolute;
		height: 100%;
		width: 100%;
		z-index: 5;
		border-radius:5px;
		&.darkgray-link-container,
		&.gray-link-container {
			background-color: #f2f2f2;
		}
		&.yellow-link-container {
			background-color: #E8E1BE;
		}
		&.blue-link-container {
			background-color: #DEEFFC;
		}
	}
	&.darkgray-link-container,
	&.gray-link-container {
		background-color: #f2f2f2;
	}
	&.yellow-link-container {
		background-color: #E8E1BE;
	}
	&.blue-link-container {
		background-color: #DEEFFC;
	}
}

#hoverBackDrop {background: rgba(34, 34, 34, .5); margin:18px; border-radius: 5px; height:740px;}
#hoverContent { border-radius: 5px; height:740px; width: calc(100% - 24px); position:relative;}

#assbgImg {border-radius: 5px;}
#assbgImg img {width:100%; height:150px; object-fit:cover; border-top-left-radius: 5px; border-top-right-radius: 5px;}
#key_class {
	position: absolute; 
	top:6px; 
	right:0px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background-color:rgba(34,34,34,.8);	
	span {
		padding: 0px 12px 0px 28px;
		font-size:12px;
	}
}
address {padding-left:12px; padding-right:12px; font-size: 16px; line-height:18px; margin-top:4px;
	span {
		font-size:15px;
	}
}
#offHours {padding-left:12px; padding-right:12px; margin-top: 10px; }
#offHours ul {font-size: 16px; line-height:18px;
	span {
		font-size:14px;
	}
}
#offContact {padding-left:12px; padding-right:12px; font-size:16px; line-height:18px; margin-top:10px;
	span {
		font-size:14px;			
	}	
}
#offContact li {padding-top:2px;}
#govInfo {padding-left:12px; padding-right:12px; margin-top: 10px; 
	span {
		font-size:14px;
	}
}
#govInfo li {font-size: 16px; line-height:18px;}
#hasPrc {		
	img {
		width:225px; 
		height:146px; 
		box-shadow: -4px 4px 5px rgba(0.5, 0.5, 0.5, .3);	
	}
	span {font-style: italic; font-size:14px; padding-bottom:4px; font-weight:bold; }
}

#vanguardInfo {padding-left:12px; padding-right:12px; margin-top:32px; position: absolute; bottom: 125px;}
#vanguardInfo .adBtn {background-color:#356ba5; color:#ffffff; font-size:12px; border-radius:10px; cursor:pointer; }
#vanguardInfo .adBtn:hover {background-color:$themeBlack; color:#ffffff; font-size:12px; border-radius:10px; cursor:pointer; }
#vanguardInfo .adBtn img {float:right; padding-right:10px; padding-top:8px; padding-bottom:8px;}
.btnClass {float:left; text-align:center; width:72%; padding:10px;line-height:16px;}

#assContact {width:100%; position: absolute; bottom: 0; background-color:#ccc; padding-bottom:12px; height:auto; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; }
#contactAssInfo { 
	padding-top: 6px; padding-left:12px; padding-right:12px; padding-bottom:32px; width:100%; 	
	ul {
		padding-bottom:4px;
		margin-top:-10px;		
		word-wrap: break-word;
		white-space:normal;
		width:75%;
		
		li {
			width:100%;
			margin-top:0px;
			padding-top:none;
			white-space: normal;
			height: auto;
			word-wrap: break-word;
			padding-bottom:2px;
			h4{
				padding-top: 8px;
				line-height:16px;
			}
			a {
				white-space: normal;
				height: auto;
				word-wrap: break-word;}
		}
		
	}
}
#assImg {width: 75px; height:75px; float:left;}
#assNoImg {width: 75px; height:75px; float:left; display:none;}
#assImg img {width:100%; height:75px; object-fit:cover;}
#assInfo {padding-left:6px; float:left; font-size:14px; line-height:16px;}
#searchContent dl {padding:12px; }
#searchContent dt {font-size: 16px; line-height:16px;}
#searchContent dd {font-size: 14px; float:left; padding-right:12px;}
#hoverContent a {color:$themeColor;}
#hoverContent a:hover {color:$themeColor; text-decoration:underline;}
#hoverContent {
	.header_links:hover {text-decoration:none;}
}
#hoverContent {
	.tel_hover {color:#000; text-decoration:none;}
}
#hoverContent {
	.tel_hover:hover {color:#000; text-decoration:none;}
}
#hoverSection {height:760px;}
.LoginNote {font-size: 10px; font-style: italic; line-height:10px;}


#counterdiv {background-color:$themeBlack; color:#fff;  }
#pageCounter{float:right; 
	color:#fff; 
	text-align:right; 
	padding:2px 32px 4px 8px;
	font-size:12px;
	

}

#warning {
	padding: 24px; font-weight: bold; background: #A8A8A8;
}
#warning p { margin-bottom: 12px; }
#warning ul { text-align: right; }
#warning li { display: inline; margin-right: 24px; padding: 6px; border: solid 2px #111111; }

#additional{text-align:center;}
#additional a:hover{border-bottom:solid 1px #5f8bff;}

h6 {
    background-image:url(images/shaded-backdrop-6.png);
	background-size: 25%;
}

/* Ad - Banner */

.ad{font-size:11px;font-weight:bold;text-align:center;color:#ffffff;}
.banner.ad{width:640px;height:90px;border:solid 1px #dddddd;margin:auto;}
#bannerAd {margin-bottom:1em; padding-top: 35px;}

/* Link Alert Pop-up */
#exitWarning {
	border-radius:24px;	
	p {
		padding:8px;
		line-height:20px;
		.emailLink {
			color: #396ba5;
			font-weight: normal ;
			text-decoration: underline;
		}
		.textLink {
			color: #396ba5;
			font-weight: bold;
			text-decoration: underline;
			
		}
		.altLink {
			color: #222;
			font-weight: bold;
			text-decoration: underline;
			
		}
		.emailLink:hover {
			color: #575757;
			font-weight: noral;
			text-decoration: underline;
		}
		.textLink:hover {
			color: #575757;
			font-weight: bold;
			text-decoration: underline;
		}
		.altLink:hover {
			color: #575757;
			font-weight: bold;
			text-decoration: underline;
		}
	}
	div {
		float:right;		
		margin-bottom:-4px;
		.buttonCancel{
			color:#ffffff;
			background-color:#222;
			border-radius: 12px;		
			padding: 8px 18px 8px 8px;
			margin:12px 2px;
			border: 2px solid #222;
			cursor: pointer;				
		}		
		.buttonCancel:hover{
			color:#222;
			background-color:#fff;
		}
	}
}

/* Footer */
#footer {
	background: $themeBlack;
	padding: 0; 
	color: #adaaad;
	font-size: 12px;
	text-align: center;
	a {
		color:#adaaad;
		&:hover {
			color: #dea436;
			text-decoration: none;
		}
	}
	.banner.ad {
		display:none;
	}
	#additional {
		background: $themeColor; 
		padding: 1em;
		a {
			color: $white;
			&:hover {color:#222; text-decoration: none;}
		}
		
		ul.additional-links {
			margin: 6px;
			li {
				display: inline;
				padding: 6px;
				border-bottom: 1px solid $white;
			}
		}
	}
	#footerContent {
		padding: 12px 0;
	}
}
@media screen and #{breakpoint(large up)} {
	#mainContent {position:absolute; top:0; width:100%;
    background: linear-gradient(to bottom, rgba(34,34,34,1) 0%, rgba(34,34,34,.7) 25%, rgba(34,34,34,.7) 50%, rgba(34,34,34,.5) 75%, rgba(34,34,34,1) 100%); 
	}
	#hasPrc {
		position: absolute; 
		bottom: 175px; 
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
	}
	#counterdiv {border-top:2px solid $themeColor; }
	
	#video_wrapper {
		height:778px;
		video {min-width:71rem; min-height:800px; max-height:1000px; margin-top:-24px}
	}
	.responsive-embed.backscreen, .flex-video.backscreen {
		padding-bottom: 63%;
	}
	#VanguardAd {
		width:100%;
		height:100%;
		background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
		background-size: 400% 400%;
		overflow: hidden;
		animation: gradient 20s ease infinite;
		@keyframes gradient {
			0% {
				background-position: 0% 100%;
			}
			50% {
				background-position: 50% 50%;
			}
			100% {
				background-position: 0% 100%;
			}
		}
		
		margin-top:32px;
		text-align:center;
		p {
			position: relative;
			top:25px;
			left: -7%;	
			margin-top:0px;
			font-size:54px;
			color:#ffffff;
			text-align:center;
			line-height:38px;
			z-index: 1;
			font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
			span {
				font-size:39px;
				color:#ffffff;
			}
			
			
			-webkit-animation: fadeOut 1 1s;
			-moz-animation: fadeOut 1 1s;
			-o-animation: fadeOut 1 1s;
			animation: fadeOut 1 1s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-delay: 2s;
			-moz-animation-delay: 2s;
			-o-animation-delay: 2s;
			animation-delay: 2s;
		}
		@keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-moz-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-webkit-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-o-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-ms-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}			
		.img_anima {
			position: relative;				
			margin-top:0px;
			animation-name: logoFade;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			z-index: 5;
			left:10%; 
			top:-300px;
			transform: scale(0.2);
			-webkit-animation-delay: 2.25s;
			-moz-animation-delay: 2.25s;
			-o-animation-delay: 2.25s;
			animation-delay: 2.25s;			
		}
		.slogan {
			color:#fff;
			font-size:14px;
			font-style: italic;
			margin-top:-10px;
			margin-left:95px;
		}
		@keyframes logoFade {
		  0%   {
			left:10%; 
			top:-300px;
			transform: scale(0.2);
			opacity: 1; 
		  }
		  100%  {
			left:-50%; 
			top:-250px;
			transform: scale(1);
			opacity: .10; 
		  }		 
		}
		h2 {
			width:100%;
			font-size:42px;
			color:#ffffff;
			text-align:center;			
			font-weight: 100;	
			margin-top:-600px;
			z-index: 50;
		}		
		h3 {
			width:100%;
			font-size:20px;
			margin-top:-10px;
			color:#ffffff;
			text-align:center;
			text-transform:uppercase;
			z-index: 50;
		}
		h4 {
			width:100%;
			font-size:16px;
			margin-top:10px;
			margin-bottom:12px;			
			text-transform:uppercase;			
			z-index: 50;
			color:#ffffff;
			text-align:center;
			
		}
		.fade_header1, .fade_header2, .fade_header3 {
			opacity:0;
		}
		.fade_header1 {
			  animation: fadeIn 5s;
			  -webkit-animation: fadeIn 5s;
			  -moz-animation: fadeIn 5s;
			  -o-animation: fadeIn 5s;
			  -ms-animation: fadeIn 5s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4s;
			  -moz-animation-delay: 4s;
			  -o-animation-delay: 4s;
			  animation-delay: 4s;
		}
		.fade_header2 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4.5s;
			  -moz-animation-delay: 4.5s;
			  -o-animation-delay: 4.55s;
			  animation-delay: 4.5s;
		}
		.fade_header3 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 6s;
			  -moz-animation-delay: 6s;
			  -o-animation-delay: 6s;
			  animation-delay: 6s;			
		}	
		.shimmer {
			animation: shimmer;
			-webkit-animation: shimmer;
			-moz-animation: shimmer;
			-o-animation: shimmer;
			-ms-animation: shimmer;
			color: rgba(241, 190, 72, 0.1);
			background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			-webkit-background-size: 75px 100%;
			-moz-background-size: 125px 100%;
			background-size: 125x 100%;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			background-clip: text;
			-webkit-animation-duration: 3s;
			-moz-animation-duration: 3s;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-color: #fff;
			-webkit-animation-delay: 8s;
			-moz-animation-delay: 8s;
			-o-animation-delay: 8s;
			animation-delay: 8s;
			opacity: 1;
			z-index:50;
			padding: 0px 125px;
		}
		@keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-moz-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-webkit-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-o-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-ms-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}		
		@-moz-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }			
		}
		@-webkit-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@-o-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}		
		.buttonv {
			font-size: 24px;
			color:#ffffff;
			background-color:#222222;
			border-radius: 5px;
			border: 2px solid #222222;
			padding: 10px 12px 10px 12px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonw {
			font-size: 24px;
			color:#ffffff;
			background-color: transparent;
			border: 2px solid #ffffff;
			border-radius: 5px;
			padding: 10px 24px 10px 24px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonv:hover {
			background-color:#ccc;
			border-radius: 5px;
			border: 2px solid #ccc;
			color:#222;
			
		}
		.buttonw:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: #222;
		}
		div {
			padding-bottom:18px;
			z-index: 50;
		}
		
	}
	#footer {border-top:4px solid #f1f1f1; margin-top:36px;}
}
@media screen and #{breakpoint(large only )} {
	body {
		
		#header {
			#statesList {
				li {
					a {
//						padding-left: .5em;
//						padding-right: .5em;
					}
				}
			}
		}
	}
	#mainContent {position:absolute; top:0; width:100%;
    background: linear-gradient(to bottom, rgba(34,34,34,1) 0%, rgba(34,34,34,.7) 25%, rgba(34,34,34,.7) 50%, rgba(34,34,34,.5) 75%, rgba(34,34,34,1) 100%); 
	}
	
	#video_wrapper {
		height:778px;
		video {min-width:82rem; min-height:850px; max-height:1000px;}
	}
	#counterdiv {border-top:2px solid $themeColor; }
	#hasPrc {
		position: absolute; 
		bottom: 175px; 
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
		text-align: center;
	}
	#contactAssInfo { 
		padding-top: 6px; padding-left:12px; padding-right:12px; padding-bottom:32px; width:100%; 	
		ul {
			padding-bottom:4px;
			margin-top:-10px;
			width:65%;
			word-wrap: break-word;
			white-space:normal;
			li {
				width:100%;
				margin-top:0px;
				padding-top:none;
				white-space: normal;
				height: auto;
				word-wrap: break-word;
				h4{
					padding-top: 8px;
					white-space: normal;
					height: auto;
					line-height:14px;
					word-wrap: break-word;
				}
				a {
					white-space: normal;
					height: auto;
					word-wrap: break-word;}
			}
			
		}
	}
	#VanguardAd {
		width:100%;
		height:100%;
		background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
		background-size: 400% 400%;
		overflow: hidden;
		animation: gradient 20s ease infinite;
		@keyframes gradient {
			0% {
				background-position: 0% 100%;
			}
			50% {
				background-position: 50% 50%;
			}
			100% {
				background-position: 0% 100%;
			}
		}
		
		margin-top:32px;
		text-align:center;
		p {
			position: relative;
			top:25px;
			left: -10%;	
			margin-top:0px;
			font-size:54px;
			color:#ffffff;
			text-align:center;
			line-height:38px;
			z-index: 1;
			font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
			span {
				font-size:39px;
				color:#ffffff;
			}
			
			
			-webkit-animation: fadeOut 1 1s;
			-moz-animation: fadeOut 1 1s;
			-o-animation: fadeOut 1 1s;
			animation: fadeOut 1 1s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-delay: 2s;
			-moz-animation-delay: 2s;
			-o-animation-delay: 2s;
			animation-delay: 2s;
		}
		@keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-moz-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-webkit-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-o-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-ms-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}			
		img_anima {
			position: relative;				
			margin-top:0px;
			animation-name: logoFade;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			z-index: 5;
			left:11%; 
			top:-300px;
			transform: scale(0.2);
			-webkit-animation-delay: 2.25s;
			-moz-animation-delay: 2.25s;
			-o-animation-delay: 2.25s;
			animation-delay: 2.25s;			
		}
		.slogan {
			color:#fff;
			font-size:14px;
			font-style: italic;
			margin-top:-10px;
			margin-left:95px;
		}
		@keyframes logoFade {
		  0%   {
			left:10%; 
			top:-300px;
			transform: scale(0.2);
			opacity: 1; 
		  }
		  100%  {
			left:-50%; 
			top:-250px;
			transform: scale(1);
			opacity: .10; 
		  }		 
		}
		h2 {
			width:100%;
			font-size:42px;
			color:#ffffff;
			text-align:center;			
			font-weight: 100;	
			margin-top:-600px;
			z-index: 50;
		}		
		h3 {
			width:100%;
			font-size:20px;
			margin-top:-10px;
			color:#ffffff;
			text-align:center;
			text-transform:uppercase;
			z-index: 50;
		}
		h4 {
			width:100%;
			font-size:14px;
			margin-top:10px;
			margin-bottom:12px;			
			text-transform:uppercase;			
			z-index: 50;
			color:#ffffff;
			text-align:center;
			
		}
		.fade_header1, .fade_header2, .fade_header3 {
			opacity:0;
		}
		.fade_header1 {
			  animation: fadeIn 5s;
			  -webkit-animation: fadeIn 5s;
			  -moz-animation: fadeIn 5s;
			  -o-animation: fadeIn 5s;
			  -ms-animation: fadeIn 5s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4s;
			  -moz-animation-delay: 4s;
			  -o-animation-delay: 4s;
			  animation-delay: 4s;
		}
		.fade_header2 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4.5s;
			  -moz-animation-delay: 4.5s;
			  -o-animation-delay: 4.55s;
			  animation-delay: 4.5s;
		}
		.fade_header3 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 6s;
			  -moz-animation-delay: 6s;
			  -o-animation-delay: 6s;
			  animation-delay: 6s;			
		}	
		.shimmer {
			animation: shimmer;
			-webkit-animation: shimmer;
			-moz-animation: shimmer;
			-o-animation: shimmer;
			-ms-animation: shimmer;
			color: rgba(241, 190, 72, 0.1);
			background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			-webkit-background-size: 75px 100%;
			-moz-background-size: 125px 100%;
			background-size: 125x 100%;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			background-clip: text;
			-webkit-animation-duration: 3s;
			-moz-animation-duration: 3s;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-color: #fff;
			-webkit-animation-delay: 8s;
			-moz-animation-delay: 8s;
			-o-animation-delay: 8s;
			animation-delay: 8s;
			opacity: 1;
			z-index:50;
			padding: 0px 75px;
		}
		@keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-moz-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-webkit-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-o-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-ms-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}		
		@-moz-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }			
		}
		@-webkit-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@-o-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}		
		.buttonv {
			font-size: 24px;
			color:#ffffff;
			background-color:#222222;
			border-radius: 5px;
			border: 2px solid #222222;
			padding: 10px 12px 10px 12px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonw {
			font-size: 24px;
			color:#ffffff;
			background-color: transparent;
			border: 2px solid #ffffff;
			border-radius: 5px;
			padding: 10px 24px 10px 24px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonv:hover {
			background-color:#ccc;
			border-radius: 5px;
			border: 2px solid #ccc;
			color:#222;
			
		}
		.buttonw:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: #222;
		}
		div {
			padding-bottom:18px;
			z-index: 50;
		}		
	}
	#footer {border-top:4px solid #f1f1f1;}
	
}
@media screen and #{breakpoint(medium down)} {
	body {
		
		#header {
			.top-bar {
				.top-bar-left {
					.headerLogos {
						h1 {
							cursor:pointer;
							font-size: clamp(1.5em, 4vw, 1.75rem);
						}
						.dropdown-pane {top:62px !important;}
						#statesDropdown {
							ul {
								li {
									padding: 0;
									h1 {
										font-size: 1.75rem;
										bottom: 0;
									}
								}
							}
						}
					}
				}
				.top-bar-right {
					.header-logo {
						& > img {
							max-width: 250px;
						}
					}
				}
			}
		}
	}
	#pageContent {height: 1456px; background-color:#222;}
	#mainContent {position:absolute; top:0; width:100%; height:1430px;
    background: linear-gradient(to bottom, rgba(34,34,34,1) 0%, rgba(34,34,34,.7) 25%, rgba(34,34,34,.7) 50%, rgba(34,34,34,.5) 75%, rgba(34,34,34,1) 100%); 
	}
	#video_wrapper {height:800px;
		video {min-width:92rem; min-height:1000px; max-height:1000px;}
	}
	#hoverSection {height:625px; 
		margin-top:-4px;
		#counterdiv {border-top:2px solid $themeColor;}
	}
	
	#hoverBackDrop {
		background-color:#222;
		margin:0px 4px 4px 4px;
		border-radius:0px;
		height:100%;
		border-top:4px solid #222;
		
		#hoverContent {
			border-radius:0px;
			height:100%;
			width:100%;		
		}		
	}
	#offInfo {
		width:40%;
		float:left;
	}	
	#hasPrc {
		width:60%;
		float:left;
	}
	#govInfo{
		width:60%;
		float:left;
	}
	#assbgImg img {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}
	#VanguardAd {
		width:100%;
		height:100%;
		background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
		background-size: 400% 400%;
		overflow: hidden;
		animation: gradient 20s ease infinite;
		@keyframes gradient {
			0% {
				background-position: 0% 100%;
			}
			50% {
				background-position: 50% 50%;
			}
			100% {
				background-position: 0% 100%;
			}
		}
		
		margin-top:32px;
		text-align:center;
		p {
			position: relative;
			top:25px;
			left: -8%;	
			margin-top:0px;
			font-size:54px;
			color:#ffffff;
			text-align:center;
			line-height:38px;
			z-index: 1;
			font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
			span {
				font-size:39px;
				color:#ffffff;
			}
			
			
			-webkit-animation: fadeOut 1 1s;
			-moz-animation: fadeOut 1 1s;
			-o-animation: fadeOut 1 1s;
			animation: fadeOut 1 1s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-delay: 2s;
			-moz-animation-delay: 2s;
			-o-animation-delay: 2s;
			animation-delay: 2s;
		}
		@keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-moz-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-webkit-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-o-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}
		@-ms-keyframes fadeOut {
			  0% { opacity: 1; }
			  100% { opacity: 0; }
			}			
		.img_anima {
			position: relative;				
			margin-top:0px;
			animation-name: logoFade;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			-o-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			z-index: 5;
			left:21%; 
			top:-300px;
			transform: scale(0.2);
			-webkit-animation-delay: 2.25s;
			-moz-animation-delay: 2.25s;
			-o-animation-delay: 2.25s;
			animation-delay: 2.25s;			
		}
		.slogan {
			color:#fff;
			font-size:14px;
			font-style: italic;
			margin-top:-10px;
			margin-left:95px;
		}
		@keyframes logoFade {
		  0%   {
			left:21%; 
			top:-300px;
			transform: scale(0.2);
			opacity: 1; 
		  }
		  100%  {
			left:-50%; 
			top:-250px;
			transform: scale(1);
			opacity: .10; 
		  }		 
		}
		h2 {
			width:100%;
			font-size:42px;
			color:#ffffff;
			text-align:center;			
			font-weight: 100;	
			margin-top:-600px;
			z-index: 50;
		}		
		h3 {
			width:100%;
			font-size:20px;
			margin-top:-10px;
			color:#ffffff;
			text-align:center;
			text-transform:uppercase;
			z-index: 50;
		}
		h4 {
			width:100%;
			font-size:14px;
			margin-top:10px;
			margin-bottom:12px;			
			text-transform:uppercase;			
			z-index: 50;
			color:#ffffff;
			text-align:center;
					
		}
		.fade_header1, .fade_header2, .fade_header3 {
			opacity:0;
		}
		.fade_header1 {
			  animation: fadeIn 5s;
			  -webkit-animation: fadeIn 5s;
			  -moz-animation: fadeIn 5s;
			  -o-animation: fadeIn 5s;
			  -ms-animation: fadeIn 5s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4s;
			  -moz-animation-delay: 4s;
			  -o-animation-delay: 4s;
			  animation-delay: 4s;
		}
		.fade_header2 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 4.5s;
			  -moz-animation-delay: 4.5s;
			  -o-animation-delay: 4.55s;
			  animation-delay: 4.5s;
		}
		.fade_header3 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 6s;
			  -moz-animation-delay: 6s;
			  -o-animation-delay: 6s;
			  animation-delay: 6s;			
		}	
		.shimmer {
			animation: shimmer;
			-webkit-animation: shimmer;
			-moz-animation: shimmer;
			-o-animation: shimmer;
			-ms-animation: shimmer;
			color: rgba(241, 190, 72, 0.1);
			background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			-webkit-background-size: 75px 100%;
			-moz-background-size: 125px 100%;
			background-size: 125x 100%;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			background-clip: text;
			-webkit-animation-duration: 3s;
			-moz-animation-duration: 3s;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-color: #fff;
			-webkit-animation-delay: 8s;
			-moz-animation-delay: 8s;
			-o-animation-delay: 8s;
			animation-delay: 8s;
			opacity: 1;
			z-index:50;
			padding: 0px 75px;
			width:100%;	
			margin:0px -70px;
		}
		@keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-moz-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-webkit-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-o-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-ms-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}		
		@-moz-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }			
		}
		@-webkit-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@-o-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}		
		.buttonv {
			font-size: 18px;
			color:#ffffff;
			background-color:#222222;
			border-radius: 5px;
			border: 2px solid #222222;
			padding: 10px 12px 10px 12px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonw {
			font-size: 18px;
			color:#ffffff;
			background-color: transparent;
			border: 2px solid #ffffff;
			border-radius: 5px;
			padding: 10px 24px 10px 24px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonv:hover {
			background-color:#ccc;
			border-radius: 5px;
			border: 2px solid #ccc;
			color:#222;
			
		}
		.buttonw:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: #222;
		}
		div {
			padding-bottom:18px;
			z-index: 50;
		}		
	}
	
	#footer {
		width:100%;
		margin-top: 48px;
	}
	
	
}
@media screen and #{breakpoint(small only)} {
	body {
		
		#header {
			.top-bar {
				padding: 0;
				.top-bar-left {
					.headerLogos {
						padding-top:10px;
						h1 {
							font-size: clamp(1.5em, 4vw, 3em);
							cursor:pointer;
							& > i.sf-replace {
									color: $themeColor;
									font-size: 2.0em;
									margin-left: 5px;
									line-height: 1em;
									&::before {
										width: 100%;
										text-align: center;
									}
								}
							.header-text {
								margin-left: -1.5rem;
								font-size: clamp(1.0em, 4vw, 3em);
							}
						}
						.dropdown-pane {top:31px !important;}
						#statesDropdown {
							width: 100%;
							
							ul {
								li {
									h1 {
										font-size: clamp(1.4em, 4vw, 3em);
										span {
											margin-left: -2.5rem;
										}
										background-color:#222;
									}
								}
							}
						}
					}
				}
			}
		}
		#pageContent {
			#mainContent {
				height: unset;
			}
		}
		#footer {
			width:100%;
			margin-top:24px;
			
			#footerContent {
				.cell {
					text-align: center;
					&.footer-meta {
						border-top: 1px solid #555;
						margin-top: 1em;
						padding-top: 1em;
					}
				}
			}
		}
	}
	.key {
		ul{
			margin-left:10px;
			li {
				margin-left:0px;
				padding-right: 16px;
				float: left;
				font-size: 10px;
			}
		}
	
	}
	#pageContent {height: 600px; background-color:#222; z-index:50;}
	.illinois_mContent { height: 490px !important; }
	.iowa_mContent { height: 600px !important; }
	.minnesota_mContent { height: 490px !important; }
	.missouri_mContent { height: 492px !important; }
	.nebraska_mContent { height: 492px !important; }
	.northDakota_mContent { height: 455px !important; }
	.southDakota_mContent { height: 490px !important; }
	#mainContent {width:100%; background-color:#222; }
	#contWrapper {padding-left:0px; padding-right:0px; max-width:unset; margin-top:-4px; }	
	.button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {background-color:#222;border-top:1px solid #fff; border-bottom: 1px solid #fff;}
	.button:hover, .button:focus {background-color:#222;} 
	#mobileContHeader {
		h2 {padding: 10px  2px 4px 10px;}			
	}
	#counties_m {		
		ul{
			margin:10px 0px;	
			li {				
				box-shadow: 0px 2px 6px 4px rgba(50, 50, 50, 0.75);
				ul {
					margin:0px 0px;
					 
					li {
						border-bottom:1px solid #222;
						border-top:1px solid #222;
						box-shadow: none;
					}
				}
			}
		}
		h3{font-size:18px; color:#ffffff; color:#ffffff; font-family: $gothic; background-color:none; padding:0px 0px; line-height:.75;  }
	}
	.menu {background-color:#575757;}
	.accordion-menu {
	  .is-accordion-submenu {			
			a {padding:.7rem 2.0rem;  }			
		}
	}
	
	.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {left:.5rem;}
	#VanguardAd {
		width:100%;
		height:100%;
		background: linear-gradient(45deg, #545488, #396ba5, #12284B, #23d5ab);
		background-size: 400% 400%;		
		animation: gradient 20s ease infinite;
		@keyframes gradient {
			0% {
				background-position: 0% 100%;
			}
			50% {
				background-position: 50% 50%;
			}
			100% {
				background-position: 0% 100%;
			}
		}		
		margin-top:32px;
		text-align:center;			
		.bk_img {
			position:relative;
			background-image:url(https://www.iowaassessors.com/images/Vanguard_img/VANGUARD_White_Trans_Half_LOGO.png); 
			background-repeat: no-repeat;  
			background-position:left; 			
			height:250px;
			 align-items: center;
			justify-content: center;
			z-index:25;			
		}		
		h2 {
			width:100%;
			font-size:26px;
			color:#ffffff;
			text-align:center;			
			font-weight: 200;	
			margin-top:0px;
			z-index: 50;
			padding-top:12px;
			
		}		
		h3 {
			width:100%;
			font-size:12px;
			margin-top:-5px;
			color:#ffffff;
			text-align:center;
			text-transform:uppercase;
			z-index: 50;
		}
		h4 {
			width:100%;
			font-size:10px;
			margin-top:10px;
			margin-bottom:12px;			
			text-transform:uppercase;			
			z-index: 50;
			color:#ffffff;
			text-align:center;
					
		}
		.fade_header1, .fade_header2, .fade_header3 {
			opacity:0;
		}
		.fade_header1 {
			  animation: fadeIn 5s;
			  -webkit-animation: fadeIn 5s;
			  -moz-animation: fadeIn 5s;
			  -o-animation: fadeIn 5s;
			  -ms-animation: fadeIn 5s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;			  
		}
		.fade_header2 {
			  animation: fadeIn 5s;
			  -webkit-animation: fadeIn 5s;
			  -moz-animation: fadeIn 5s;
			  -o-animation: fadeIn 5s;
			  -ms-animation: fadeIn 5s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 1s;
			  -moz-animation-delay: 1s;
			  -o-animation-delay: 1s;
			  animation-delay: 1s;
		}
		.fade_header3 {
			  animation: fadeIn 4s;
			  -webkit-animation: fadeIn 4s;
			  -moz-animation: fadeIn 4s;
			  -o-animation: fadeIn 4s;
			  -ms-animation: fadeIn 4s;
			  -webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			  -webkit-animation-delay: 3s;
			  -moz-animation-delay: 3s;
			  -o-animation-delay: 3s;
			  animation-delay: 3s;			
		}	
		.shimmer {
			animation: shimmer;
			-webkit-animation: shimmer;
			-moz-animation: shimmer;
			-o-animation: shimmer;
			-ms-animation: shimmer;
			color: rgba(241, 190, 72, 0.1);
			background: -webkit-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: -moz-gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			background: gradient(linear, left top, right top, from(#fff), to(#fdef16), color-stop(1, #fdef16));
			-webkit-background-size: 75px 100%;
			-moz-background-size: 125px 100%;
			background-size: 125x 100%;
			-webkit-background-clip: text;
			-moz-background-clip: text;
			background-clip: text;
			-webkit-animation-duration: 3s;
			-moz-animation-duration: 3s;
			animation-duration: 3s;
			-webkit-animation-fill-mode: forwards;
			  -moz-animation-fill-mode: forwards;
			  -o-animation-fill-mode: forwards;
			  animation-fill-mode: forwards;
			background-repeat: no-repeat;
			background-position: 0 0;
			background-color: #fff;
			-webkit-animation-delay: 4s;
			-moz-animation-delay: 4s;
			-o-animation-delay: 4s;
			animation-delay: 4s;
			opacity: 1;
			z-index:50;
			padding: 0px 75px;
			width:100%;	
			margin:0px -70px;
		}
		@keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-moz-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-webkit-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-o-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}
		@-ms-keyframes fadeIn {
			0% { opacity: 0; }
			100% { opacity: 1; }
		}		
		@-moz-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }			
		}
		@-webkit-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@-o-keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}
		@keyframes shimmer {
			0% {background-position: left;  }			
			100% {background-position: right;  }
		}		
		.buttonv {
			font-size: 16px;
			color:#ffffff;
			background-color:#222222;
			border-radius: 5px;
			border: 2px solid #222222;
			padding: 10px 12px 10px 12px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
			opacity:1;
		}
		.buttonw {
			font-size: 16px;
			color:#ffffff;
			background-color: transparent;
			border: 2px solid #ffffff;
			border-radius: 5px;
			padding: 10px 54px 10px 54px;
			margin:12px;
			cursor: pointer;
			z-index: 50;
		}
		.buttonv:hover {
			background-color:#ccc;
			border-radius: 5px;
			border: 2px solid #ccc;
			color:#222;
			
		}
		.buttonw:hover {
			background-color: #fff;
			border: 2px solid #fff;
			color: #222;
		}
		div {
			padding-bottom:18px;
			z-index: 50;
		}		
	}
	
}
