/* Colors */
$themeColor: #396ba5;
$themeBlack: #222;

/* Fonts */
$gothic: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;

@font-face {
  font-family: 'vai-icons';
  src: url('../fonts/vai-icons.eot?95963887');
  src: url('../fonts/vai-icons.eot?95963887#iefix') format('embedded-opentype'),
       url('../fonts/vai-icons.woff2?95963887') format('woff2'),
       url('../fonts/vai-icons.woff?95963887') format('woff'),
       url('../fonts/vai-icons.ttf?95963887') format('truetype'),
       url('../fonts/vai-icons.svg?95963887#vai-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="vai-icon-"]:before, [class*=" vai-icon-"]:before {
  font-family: "vai-icons";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
.vai {
	&-icon-down:before { content: '\e800'; } /* '' */
	&-icon-phone:before { content: '\e801'; } /* '' */
	&-icon-mail:before { content: '\e802'; } /* '' */
	&-icon-globe:before { content: '\e803'; } /* '' */
	&-icon-location:before { content: '\e804'; } /* '' */
	&-icon-info:before { content: '\e805'; } /* '' */
	&-icon-up:before { content: '\e806'; } /* '' */
	&-icon-fax:before { content: '\f1ac'; } /* '' */
	&-icon-copyright:before { content: '\f1f9'; } /* '' */
}

/* MOVE THESE */
pre.debug-block {
	background: #eee;
	margin: 1em;
	border: 1px solid #333;
	border-radius: 5px;
	padding: .5em;
	&.warning {
		background: #ffcc80;
	}
	&.danger {
		background: #f9adad;
	}
	&.success {
		background: #baf9ad;
	}
	&.info {
		background: #adc0f9;
	}
}
.way-offscreen {
	position: absolute;
	top: -999999px;
	left: -9999999px;
}


